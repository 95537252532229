import PageContactInfo from "./PageContactInfo";
import PageContactFields from "./PageContactFields";

const PageContact = () => {
  return (
    <div className="w-full flex flex-col justify-start items-center">
      {/**Contact Tab */}
      <div className="w-full px-8 xs:w-full py-4 flex flex-col justify-start items-center">
        {/**Contact Fields and its info */}
        <div className="w-full flex lg:flex-row xs:flex-col justify-between items-start lg:space-x-16 xs:gap-8">
          {/**Contact Info */}
          <PageContactInfo />
          {/**Contact Fields */}
          <PageContactFields />
        </div>
      </div>
    </div>
  );
};

export default PageContact;
