import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import appStore from "store/AppStore";
import Loading from "containers/Loader/Loading";

type IframePostMessgaeProps = {
  type: string;
  data: any;
}

const BlogPage: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const heightRef = useRef(0); // Ref to store the latest height
  const [height, setHeight] = useState(0);
  const { theme } = appStore();

  // Listen for iframe messages
  useEffect(() => {

    sendMessage({ type: "PARENT_THEME_MESSAGE", data: theme });

    const handleMessage = (event: MessageEvent) => {
      console.log("Received message:", event);

      

      const { iframeHeight } = event.data as { iframeHeight?: number };

      if (iframeHeight && iframeRef.current) {
        console.log("Updating iframe height:", iframeHeight);

        // Update ref
        // heightRef.current = iframeHeight;

        // Update state

        // Directly update the iframe style
        // iframeRef.current.style.height = `${iframeHeight}px`;
      }
    };

    // Add event listener for "message" event
    window.addEventListener("message", handleMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // Send theme to iframe when it changes
  useEffect(() => {
    if (iframeRef.current) {
      const message = { type: "PARENT_THEME_MESSAGE", data: "light" };
      sendMessage(message);
    }
  }, [theme]);

  useEffect(() => {
    setHeight(heightRef.current);
  }, [ heightRef]);


  const sendMessage = (data: IframePostMessgaeProps) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(data, "*");
    }
  }

  return (
    <div className="nc-BlogPage relative h-screen scrollbar-hide">
      <Helmet>
        <title>Spot.Care | Blog</title>
      </Helmet>

      {/* Conditionally render iframe or loading */}
      <iframe
        ref={iframeRef}
        src="https://blog.spot.care" // iframe content URL
        // src="http://localhost:3000" // iframe content URL
        className="w-full border-none mb-4 z-10 h-full scrollbar-hide"
        // scrolling="no" // Disable scrolling
        title="Spot.Care Blog"
      />

      {
        height === 0 &&
        <div className="absolute w-full top-1/4 flex justify-center align-middle -z-10">
          <div className="my-auto">
            <Loading />
          </div>
        </div>
      }

    </div>
  );
};

export default BlogPage;
