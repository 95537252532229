import React, { FC, useEffect, useState, useRef } from "react";
import appStore from "store/AppStore";
import { ProviderCard } from "./ProviderCard";
import Pagination from "shared/Pagination/Pagination";
import uiUseStore from "store/UIStore";
import { GoogleMap } from "components/GoogleMap/GoogleMap";
import Heading2 from "components/Heading/Heading2";
import TabFilters from "./TabFilters";
import { Providers } from "types/ProviderDetails";
import lottie from "lottie-web";
import noRecordsAnimation from "../../images/Lottie/NoRecords.json";
import Heading from "components/Heading/Heading";
import {
  ChevronUpIcon,
  FunnelIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import HeroSearchCustomToast from "components/CustomToast/HeroSearchCustomToast";
import { StatusMessages } from "constants/StatusMessages";
import { Services } from "services/service";
import { parseProviderResults } from "contains/makers";
import { APP_CONSTANTS } from "constants/AppConstants";
import Loading from "containers/Loader/Loading";
import SearchMobile from "components/SearchMobile/SearchMobile";
import { KEYS } from "constants/KeyConstants";

export interface PageListProps {}

const PageList: FC<PageListProps> = () => {
  const [searchParams] = useSearchParams();
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const NorecordContainerRef = useRef(null);

  const [selectedMarker, setSelectedMarker] = useState<Providers | null>();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const {
    providersList,
    filteredPaginatedList,
    setSavedProviderList,
    setLoader,
    setFilteredPaginatedList,
    setOriginalList,
    setProvidersList,
    userDetail,
    setPaginationDetails,
    loading,
    setIsWishlistLoaded,
    isWishlistLoaded
  } = appStore();
  const {
    isTabFiltersOpen,
    setIsTabFiltersOpen,
    isHomePage,
    isList,
    isLoggedIn,
    setServicesTag,
    CareType,
    locationvalue,
    setLocationValue,
    setHeaderRadius,
    HeaderRadius,
    setCareType,
    storeLatitude,
    storeLongitute,
    setLatitude,
    setLongitude,
    setStorePostalCode,
    storePostalCode
  } = uiUseStore();

  const filterData = {
    careType: searchParams.get("careType") || "",
    lat: parseFloat(searchParams.get("lat") || "0.0"),
    lon: parseFloat(searchParams.get("lon") || "0.0"),
    page: parseInt(searchParams.get("page") || "1"),
    pageSize: parseInt(searchParams.get("pageSize") || "10"),
    postalCode: searchParams.get("postalCode") || "",
    radius: searchParams.get("radius") || ""
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1280);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      LoadWishlist();
    } else {
      setSavedProviderList([]);
    }
  }, [isLoggedIn]);

  async function SearchOption() {
    try {
      setLoader(true);
      if (
        !filterData.careType ||
        !filterData.radius ||
        !filterData.postalCode
      ) {
        toast.custom((t) => (
          <HeroSearchCustomToast
            icon={
              <ExclamationTriangleIcon
                className="h-10 w-10 rounded-full text-yellow-400"
                aria-hidden="true"
              />
            }
            description1={
              StatusMessages.ErrorMessage.RadiusInputValidationDescription1
            }
            description2={
              StatusMessages.ErrorMessage.RadiusInputValidationDescription2
            }
            toasttype={t}
          />
        ));
      } else {
        let { data, total } = await Services.LoadCaresAgainstFilters(
          filterData
        );
        setPaginationDetails({
          total: total,
          currentPage: 1,
          totalPages: Math.ceil(total / filterData.pageSize)
        });
        let result = await parseProviderResults(data, filterData);
        setProvidersList(result);
        setOriginalList(result);
        setFilteredPaginatedList(
          result.slice(0, APP_CONSTANTS.PAGINATION_LIMIT)
        );
      }
    } catch (ex) {
    } finally {
      setLoader(false);
      const localStoredLocationValue = localStorage.getItem("LocationValue");
      setServicesTag(CareType || filterData.careType);
      setCareType(CareType || filterData.careType);
      setLocationValue(locationvalue || localStoredLocationValue || "");
      setHeaderRadius(HeaderRadius || filterData.radius || "");
      setLatitude(storeLatitude || filterData.lat || 0.0);
      setLongitude(storeLongitute || filterData.lon || 0.0);
      setStorePostalCode(storePostalCode || filterData.postalCode || "");
    }
  }

  const LoadWishlist = async () => {
    setIsWishlistLoaded(true);
    if (isLoggedIn) {
      const customerId =
        userDetail.id && userDetail.id !== 0
          ? userDetail.id
          : parseInt(localStorage.getItem(KEYS.USERID) || "0", 10);
      let wishlistData = await Services.GetWishlist(customerId);
      let wishlist: Providers[] = wishlistData.data.map((e: any) => e.provider);
      let wishlistResult = await parseProviderResults(wishlist, filterData);
      if (wishlist && wishlist.length > 0) {
        setSavedProviderList(wishlistResult);
      }
    }
    setIsWishlistLoaded(false);
  };

  useEffect(() => {
    const fetchSearchOptions = async () => {
      await SearchOption(); // Call SearchOption with await
    };

    fetchSearchOptions(); // Immediately invoke the async function
  }, []);

  useEffect(() => {
    if (filteredPaginatedList && filteredPaginatedList.length === 0) {
      if (NorecordContainerRef.current) {
        const lottieInstance = lottie.loadAnimation({
          container: NorecordContainerRef.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: noRecordsAnimation
        });

        return () => {
          lottieInstance.destroy();
        };
      }
    }
  }, [filteredPaginatedList]);

  return loading || isWishlistLoaded ? (
    <div className="h-[88vh] w-full flex justify-center items-center">
      <Loading />
    </div>
  ) : (
    <div>
      <div className="relative flex h-[88vh] scrollbar-none px-4">
        <div
          className={`flex- lg:flex-1 h-full w-full overflow-hidden ${
            isList ? "flex" : ""
          } flex-col`}
        >
          <div className="flex flex-col gap-y-3 items-start w-full 2xs:px-0 sm:px-6 py-6 2xs:py-2">
            <div className="w-full flex 2xs:flex-col md:flex-row justify-between 2xs:items-start md:items-center xs:gap-1 md:gap-0">
              {isSmallScreen && isTabFiltersOpen ? (
                <ChevronUpIcon
                  className="absolute h-5 right-0 mt-2 mr-5 md:hidden"
                  onClick={() => {
                    setIsTabFiltersOpen(false);
                  }}
                />
              ) : (
                <FunnelIcon
                  className="absolute h-5 right-0 mt-2 mr-5 md:hidden"
                  onClick={() => {
                    setIsTabFiltersOpen(true);
                  }}
                />
              )}
              <Heading2
                heading={`${providersList.length} Providers`}
                className="2xs:ml-2 sm:ml-0"
              />
              <Pagination
                className={`${isSmallScreen && !isTabFiltersOpen && `hidden`}`}
              />
            </div>
            <TabFilters
              className={`${isSmallScreen && !isTabFiltersOpen && `hidden`}`}
            />
          </div>

          <div
            className={`w-full h-full overflow-scroll scrollbar-hide px-6 pb-6 responsive-height  flex flex-col gap-y-3`}
          >
            <div className="grid grid-cols-1 gap-8">
              {filteredPaginatedList && filteredPaginatedList.length > 0 ? (
                filteredPaginatedList.map((item) => (
                  <div
                    key={item.code}
                    onMouseEnter={() => {
                      setCurrentHoverID((_) => item.code);
                      setSelectedMarker(item);
                    }}
                    onMouseLeave={() => {
                      setCurrentHoverID((_) => -1);
                      setSelectedMarker((_) => null);
                    }}
                  >
                    <ProviderCard data={item} />
                  </div>
                ))
              ) : (
                <div className="relative flex flex-col h-full max-w-full items-center justify-center">
                  <div
                    className="w-96 h-96 -mt-32"
                    ref={NorecordContainerRef}
                  ></div>
                  <Heading
                    desc={"Please search for other cares"}
                    className="text-center"
                  >
                    No providers found
                  </Heading>
                </div>
              )}
            </div>
            <div className="flex mt-4 justify-center items-center pb-6">
              <Pagination className={`${isHomePage && `hidden`}`} />
            </div>
          </div>
        </div>
        {filteredPaginatedList && filteredPaginatedList.length > 0 && (
          <div
            className={`lg:flex-1 xl:h-full xl:w-full ${
              isList ? "block" : "2xs:hidden"
            } xl:block`}
          >
            <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-full rounded-md overflow-hidden">
              <GoogleMap
                selectedId={currentHoverID}
                latLng={{
                  lat: selectedMarker?.locations[0].latitude ?? 0,
                  lng: selectedMarker?.locations[0].longitude ?? 0
                }}
              />
            </div>
          </div>
        )}
      </div>
      <SearchMobile />
    </div>
  );
};

export default PageList;
