import React, { FC } from "react";
import imagePng from "images/app/banner.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import appStore from "store/AppStore";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import uiUseStore from "store/UIStore";
import { TypeAnimation } from "react-type-animation";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const { tenantConfig, loading } = appStore();
  const { setShowHeroMobileSearch } = uiUseStore();

  const heroSearchFormMobile = () => {
    setShowHeroMobileSearch(true);
  };

  return (
    <div className="nc-PageHome relative ">
      <div className="relative xs:px-5 2xl:pr-0  space-y-24 mb-18 lg:space-y-28 lg:mb-28">
        {/* Main content container: left text + right image */}
        <div className="flex flex-col xl:flex-row justify-between 2xs:items-center xl:items-start w-full gap-10 xl:gap-20 px-10 lg:px-0 2xl:pl-14 2xl:pr-0 mt-10 xl:mt-18 3xl:mt-24">
          {/* Left Column: Text + Typing Animation */}
          <div className="w-full xl:w-8/12 flex flex-col items-center xl:items-start  space-y-8 sm:space-y-10 pb-14 xl:pb-20 xl:pr-10">
            <h2 className="font-medium text-4xl pt-8 md:text-5xl xl:text-7xl leading-[114%] opacity-0 animate-fade-in">
              Spot your
            </h2>
            <TypeAnimation
              sequence={[
                "Care",
                2000,
                "Skilled Nursing",
                1000,
                "Pharmacies",
                1000,
                "Hospitals",
                1000,
                "Home Health",
                1000,
                "Adult Day Care",
                1000,
                "Assisted Living",
                1000,
                "Transportation",
                1000,
                "Homeless Care",
                1000
              ]}
              wrapper="h2"
              speed={30}
              style={{ display: "inline-block" }}
              repeat={Infinity}
              className="font-medium text-base text-primary xs:text-3xl md:text-5xl 2xl:text-7xl leading-[114%] opacity-0 animate-fade-in"
            />
            <ButtonPrimary
              loading={loading}
              className="mt-2 mb-6 xl:hidden"
              onClick={heroSearchFormMobile}
            >
              Start your search
            </ButtonPrimary>
          </div>

          {/* Right Column: Image */}
          <div className="w-full sm:w-[60%] md:w-[45%] lg:w-[50%] xl:w-[60%] flex justify-end">
            <img
              className="xs:w-full lg:w-full rounded-3xl animate-fade-in object-cover"
              src={imagePng}
              alt="hero"
            />
          </div>
        </div>

        {/* Search Form (only on xl screens and above) */}
        <div className="hidden xl:block absolute z-10 top-40 xl:top-72 2xl:top-[22rem] left-0 2xl:left-16 mb-12 w-full">
          <HeroSearchForm />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
