import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import StartRating from "components/StartRating/StartRating";
import { ContainerMap } from "components/GoogleMap/ContainerMap";
import { Providers } from "types/ProviderDetails";
import appStore from "store/AppStore";
import {
  BuildingOfficeIcon,
  EnvelopeIcon,
  FlagIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon
} from "@heroicons/react/24/outline";
import ButtonThird from "shared/Button/ButtonThird";
import Badge from "shared/Badge/Badge";
import { TitleCase, formatPhoneNumber } from "utils/converter";
import ImageSlider from "components/ImageSlider/ImageSlider";
import uiUseStore from "store/UIStore";
import QnASections from "./QnASections";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { APP_CONSTANTS } from "constants/AppConstants";
import { KEYS } from "constants/KeyConstants";
import { StarIcon, UserGroupIcon } from "@heroicons/react/24/solid";

interface ProviderDialogProps {
  open: boolean;
  closeModal: (value: boolean) => void;
  details: Providers;
}

export const ProviderDialog: React.FC<ProviderDialogProps> = ({
  open,
  closeModal,
  details
}) => {
  const [activeTab, setActiveTab] = useState("Facts");
  const parentContainerRef = useRef<HTMLDivElement>(null); // Reference to the parent container

  useEffect(() => {
    //setting review selected to false for initial loading when there is not review it will throw null error cause if review selected is true
    setIsReviewSelected(false);
    if (details?.sections && details.sections.length > 0) {
      setActiveTab("Facts");
    } else {
      setActiveTab("Review");
    }
  }, [details, open]);

  const { setSavedProviderList, savedProviderList, careTypes } = appStore();
  const {
    setIsReportDialogOpen,
    setIsMoreInfoDialogOpen,
    setDialogProviderName,
    setDialogProviderCode,
    setShowLogin,
    setIsReviewSelected,
    setActiveSection
  } = uiUseStore();

  const scrollToReviews = () => {
    setTimeout(() => {
      if (parentContainerRef.current) {
        parentContainerRef.current.scrollTo({
          top: parentContainerRef.current.scrollHeight / 18,
          behavior: "smooth" // Smooth scrolling effect
        });
      }
    }, 200);
  };

  function isCareTypePresent(input: string): boolean {
    return careTypes.some((group) => group.careTypes.includes(input));
  }

  const isSelected = savedProviderList
    ? savedProviderList?.filter((profile) => profile.name === details.name)
        .length > 0
    : false;

  const savedProviders = () => {
    const isLoggedIn = localStorage.getItem(KEYS.ISLOGGEDIN) === "true";
    if (isLoggedIn) {
      if (!isSelected) {
        if (savedProviderList) {
          setSavedProviderList(savedProviderList.concat(details));
        } else {
          setSavedProviderList([details]);
        }
      } else {
        if (savedProviderList) {
          setSavedProviderList(
            savedProviderList?.filter((detail) => detail.name !== details.name)
          );
        }
      }
    } else {
      setShowLogin(true);
    }
  };

  const renderInformation = () => {
    return (
      <div className="py-2">
        <div className="mt-6 grid xs:grid-cols-1 md:grid-cols-2 xs:space-y-5 md:space-y-0">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <BuildingOfficeIcon className="h-6 w-6 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.locations[0].address}, {details.locations[0].city},{" "}
                {details.locations[0].state}, {details.locations[0].postalCode}
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <PhoneIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.phoneNumber.length > 0 ? (
                  formatPhoneNumber(details.phoneNumber[0].phoneNumber)
                ) : (
                  <span className="text-sm ml-2">Unavailable</span>
                )}{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6 grid xs:grid-cols-1 md:grid-cols-2 xs:space-y-5 md:space-y-0">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <MapPinIcon className="h-6 w-6 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.distanceInMiles} miles away
              </span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <EnvelopeIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-400" />
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                {details.email && details.email !== "" ? (
                  details.email
                ) : (
                  <span className="text-sm ml-2  text-neutral-500 dark:text-neutral-400">
                    Unavailable
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-6 grid xs:grid-cols-1 md:grid-cols-2 xs:space-y-5 md:space-y-0">
          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <GlobeAltIcon className="h-6 w-6 text-neutral-500 dark:text-neutral-400" />

              {details.socialMedia && details.socialMedia.length > 0 ? (
                <span className="ml-2 text-sm text-primary-900 dark:text-primary-300">
                  <a
                    href={details.socialMedia[0].socialMediaLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    Visit website
                    <i className="las la-external-link-alt ml-1"></i>
                  </a>
                </span>
              ) : (
                <span className="text-sm ml-2  text-neutral-500 dark:text-neutral-400">
                  Unavailable
                </span>
              )}
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center space-x-3 pl-2 xs:pl-1">
              <StartRating
                point={details.rating?.overall}
                reviewCount={5}
                className="text-3xl"
              />
            </div>
          </div>
        </div>
        <div
          className={`mt-6 xs:h-96 md:h-64 grid xs:grid-cols-1 ${
            details.images && details.images.length > 0
              ? `md:grid-cols-2`
              : `md:grid-cols-1`
          }  gap-4`}
        >
          {details.images && details.images.length > 0 && (
            <div className="relative rounded-xl overflow-hidden">
              <ImageSlider
                images={
                  details.images && details.images.length > 0
                    ? details.images.map((img) => img.imagePath)
                    : [APP_CONSTANTS.DUMMY_IMAGE]
                }
              />
            </div>
          )}

          <div
            className={`rounded-xl overflow-hidden md:h-full ${
              details.images && details.images.length > 0
                ? "xs:h-44"
                : "xs:h-full"
            }`}
          >
            <ContainerMap markers={details} />
          </div>
        </div>
      </div>
    );
  };

  const cmsRating = () => {
    return (
      <div className="py-7">
        <h3 className="text-lg font-medium">
          CMS Quality Ratings
          <span className="ml-2 text-xs text-primary-900 dark:text-primary-300">
            <a
              href={details.rating?.moreinfo}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              medicare.gov
              <i className="las la-external-link-alt ml-1"></i>
            </a>
          </span>
        </h3>
        <div className="mt-6 relative ">
          <div className="mt-6 grid xs:grid-cols-2 md:grid-cols-3">
            <div className="space-y-3 mb-5">
              <div className=" items-center space-y-2">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Overall Rating
                </span>
                <StartRating point={details.rating?.overall} reviewCount={5} />
              </div>
            </div>
            <div className="space-y-3 mb-5">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Health Inspection
                </span>
                <StartRating
                  point={details.rating?.healthInspection}
                  reviewCount={5}
                />
              </div>
            </div>
            <div className="space-y-3 mb-5">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Quality Measure
                </span>
                <StartRating
                  point={details.rating?.qualityMeasure}
                  reviewCount={5}
                />
              </div>
            </div>
            <div className="space-y-3 mb-5">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Staff Rating
                </span>
                <StartRating
                  point={details.rating?.staffRating}
                  reviewCount={5}
                />
              </div>
            </div>
            <div className="space-y-3">
              <div className="space-y-3">
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  Long Stay Quality Mesaure
                </span>
                <StartRating
                  point={details.rating?.longStayQuality}
                  reviewCount={5}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const colors = ["indigo"];

  const renderServices = () => {
    return (
      <div className="py-4">
        <h3 className="text-lg font-medium">Services Available</h3>
        <div className="mt-6 relative ">
          <div className="mt-6 w-full flex justify-between items-center">
            <div className="flex flex-wrap gap-2">
              {details.services.map((profile) => {
                if (isCareTypePresent(profile)) {
                  return (
                    <Badge
                      name={
                        <div className="flex items-center">
                          <span className="m-1 text-sm text-center">
                            {profile}
                          </span>
                        </div>
                      }
                      color={colors[Math.floor(Math.random() * colors.length)]}
                    />
                  );
                }
              })}
            </div>
            {details?.totalReview &&
              parseInt(details?.totalReview?.totalRating) > 0 && (
                <div className="flex items-center justify-center gap-4 cursor-pointer">
                  <div
                    className="flex flex-col items-center justify-center gap-2"
                    onClick={() => {
                      setIsReviewSelected(true);
                      setActiveSection(0);
                      scrollToReviews();
                    }}
                  >
                    <div className="p-3 bg-yellow-200 rounded-full">
                      <StarIcon className="h-5 w-5 text-yellow-500 dark:text-yellow-400" />
                    </div>
                    <p className="font-semibold text-sm text-center hover:text-blue-700 hover:underline">
                      {details?.totalReview?.totalRating ?? 0} Rating
                    </p>
                  </div>
                  <div
                    className="flex flex-col items-center justify-center gap-2"
                    onClick={() => {
                      setIsReviewSelected(true);
                      setActiveSection(0);
                      scrollToReviews();
                    }}
                  >
                    <div className="p-3 bg-green-200 rounded-full">
                      <UserGroupIcon className="h-5 w-5 text-green-600 dark:text-green-600" />
                    </div>
                    <p className="font-semibold text-sm text-center hover:text-blue-700 hover:underline">
                      {details?.totalReview?.totalReviews ?? 0}
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  const handleCloseMenu = () => {};
  //<ReviewSection data={details.totalReview} />
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className=" w-screen fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseMenu}
      >
        <div className="min-h-screen min-w-full text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            className="inline-block py-8 px-2 h-screen w-full max-w-full"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-flex flex-col w-full max-w-5xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
              <div className="flex flex-row text-neutral-700 dark:text-neutral-300 text-center justify-between items-center border-b dark:border-neutral-800 py-3 px-2 relative">
                <p className="text-xl font-semibold flex-grow flex items-center justify-center">
                  <span>{TitleCase(details.name!)}</span>
                  {/* {details.rating?.overall! >= 5 && (
                    <Tooltip
                      text={"Verified"}
                      position={"left-[0px] top-[30px]"}
                    >
                      <CheckBadgeIcon className="h-7 w-7 mr-1 text-emerald-500 dark:text-emerald-100 ml-1" />
                    </Tooltip>
                  )}
                  {details.rating?.overall! <= 2 && (
                    <Tooltip
                      text={"Poorly rated"}
                      position={"left-[0px] top-[30px]"}
                    >
                      <ExclamationCircleIcon className="h-7 w-7 mr-1 text-red-500 dark:text-red-500 ml-1" />
                    </Tooltip>
                  )} */}
                </p>
                <span className="flex-shrink-0">
                  <ButtonClose
                    onClick={() => {
                      closeModal(false);
                    }}
                  />
                </span>
                <span className="absolute xs:right-4 md:right-auto md:left-4 xs:top-[5rem] sm:top-[4rem] md:top-[2.5rem] lg:top-4 xs:hidden sm:block">
                  {/* {details.isPreffered && (
                    <Badge
                      name={
                        <div className="flex items-center">
                          <img
                            src={buildTenantDetails().logo.symbol}
                            className="h-4 w-4"
                            alt="load again"
                          />
                          <span className="ml-1 text-sm">Recommended</span>
                        </div>
                      }
                      color="purple"
                    />
                  )} */}
                </span>
              </div>
              <div
                className="flex-grow overflow-y-auto scrollbar-hide"
                ref={parentContainerRef}
              >
                <div className="xs:px-2 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                  {renderInformation()}
                  {renderServices()}
                  {details.isRatingsAviable && cmsRating()}

                  {details &&
                    details.sections &&
                    details.sections?.length > 0 && (
                      <div className="pt-5 mx-auto h-fit">
                        <h3 className="text-lg font-medium">
                          Facts & Features
                        </h3>
                        <QnASections
                          data={details.sections ?? []}
                          totalReview={details.totalReview}
                        />
                      </div>
                    )}
                </div>
              </div>

              <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between 2xs:items-center xs:justify-between xs:flex-row 2xs:flex-col 2xs:justify-center 2xs:space-y-3 xs:space-y-0">
                <ButtonThird
                  onClick={() => {
                    setIsReportDialogOpen(true);
                    setIsMoreInfoDialogOpen(false);
                    setDialogProviderName(details.name);
                    setDialogProviderCode(details.code);
                  }}
                  className="bg-red-500 hover:bg-red-700 px-4 py-2 border text-white border-none outline-0 xs:text-xs"
                >
                  <FlagIcon className="h-4 w-4 ml-1 mr-1 stroke-current stroke-2" />
                  Report an issue
                </ButtonThird>

                <ButtonPrimary
                  onClick={savedProviders}
                  className={`${
                    isSelected
                      ? "bg-red-500 hover:bg-red-700"
                      : "bg-primary-500 hover:bg-primary-700"
                  } px-4 py-2 border text-white border-none outline-0 xs:text-xs`}
                >
                  {isSelected ? "Remove from " : "Add to "}wishlist
                </ButtonPrimary>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
