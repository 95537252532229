import React, { FC, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/Pagination";
import appStore from "store/AppStore";
import { ProviderCard } from "./ProviderCard";
import { Providers } from "types/ProviderDetails";
import { GoogleMapV2 } from "components/GoogleMap/GoogleMapV2";
import CustomHeading from "components/Heading/CustomHeading";
import uiUseStore from "store/UIStore";

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<Providers | null>();

  const { filteredPaginatedList, providersList } = appStore();
  const { homePageListingProviderName } = uiUseStore();

  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-full 2xl:w-[50%] flex-shrink-0 md:pr-4 2xl:pr-12 ">
          <CustomHeading
            heading={`Skilled Nursing in ${homePageListingProviderName}`}
            subHeading={`${providersList.length} providers for you`}
          />
          <div className="grid grid-cols-1 gap-8">
            {filteredPaginatedList.map((item) => (
              <div
                key={item.code}
                onMouseEnter={() => {
                  setCurrentHoverID((_) => item.code);
                  setSelectedMarker(item);
                }}
                onMouseLeave={() => {
                  setCurrentHoverID((_) => -1);
                  setSelectedMarker((_) => null);
                }}
              >
                <ProviderCard data={item} />
              </div>
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination pageCount={5} />
          </div>
        </div>
        {/* MAPPPPP */}
        <div
          className={`2xl:flex-grow 2xl:static 2xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50 " : "2xs:hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <GoogleMapV2
              selectedId={currentHoverID}
              latLng={{
                lat: selectedMarker?.locations[0].latitude ?? 0,
                lng: selectedMarker?.locations[0].longitude ?? 0
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
