import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Services } from "services/service";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import appStore from "store/AppStore";
import * as yup from "yup";
import care from "images/banners/care.png";
import { useNavigate } from "react-router-dom";
import uiUseStore from "store/UIStore";
import FormError from "components/FormError/FormError";
import { StatusMessages } from "constants/StatusMessages";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { KEYS } from "constants/KeyConstants";
import { useState } from "react";
import { NotifierModel } from "types/NotifierModel";
import Notifier from "components/Notifier/Notifier";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import useCookie from "utils/useCookies";

interface FormData {
  password: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined] as const, "Passwords must match") // Explicitly typing the array
    .required("Confirm Password is required")
});

const PageResetPassword = () => {
  const { loading, setLoader } = appStore();
  const { setIsLoginPage, setIsTokenRecieved, setIsResetPassword } =
    uiUseStore();

  const { getCookie, removeCookie } = useCookie();
  const [notifierDetails, setNotifierDetails] = useState<NotifierModel>({
    message: "",
    mode: "error"
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setLoader(true);
      const token = getCookie(KEYS.TOKEN);
      if (token) {
        let result = await Services.ResetPassword(data.password, token);
        if (result) {
          setIsLoginPage(true);
          toast.success(StatusMessages.SuccessMessages.PasswordReset);
          removeCookie(KEYS.TOKEN);
          setIsTokenRecieved(false);
        }
      } else {
        setNotifierDetails({
          message: StatusMessages.ErrorMessage.PasswordReset,
          mode: "error"
        });
      }
      removeCookie(KEYS.TOKEN);
    } catch (error) {
      setNotifierDetails({
        message: StatusMessages.ErrorMessage.PasswordResetFailed,
        mode: "error"
      });
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className={`nc-PageLogin rounded-xl flex `}>
      <div className="2x:w-[15rem] xs:w-[18rem] 2xs:h-[43rem] sm:w-[30rem] md:w-[50rem] md:h-[43rem] lg:w-[70rem] lg:h-[43rem] xs:p-2 lg:p-2 rounded-lg  flex flex-col md:flex-row ">
        {/* Left Side */}
        <div className="hidden md:flex md:w-1/2 bg-gray-100 rounded-l-lg flex-col justify-center">
          <div className="relative h-full w-full rounded-lg overflow-hidden">
            <img
              src={care}
              className="object-cover h-full w-full"
              alt="Healthcare Image"
            />
          </div>
        </div>
        {/* Right Side */}
        <div className="w-full my-20 2xs:my-5 md:w-1/2 2xs:p-0 sm:p-8 text-center align-middle justify-center">
          <div className="justify-center mb-8">
            <h3 className="text-[1.75rem] font-medium text-left py-[11px]">
              You're Verified 🎉
            </h3>
            <h4 className="font-normal text-left text-[0.9rem] text-gray-500">
              Your email address is verified successfully
            </h4>
          </div>
          <Notifier
            notifierState={notifierDetails.message !== ""}
            message={notifierDetails.message}
            mode={notifierDetails.mode}
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full space-y-1 mt-4"
          >
            <div className="text-left">
              <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1 font-medium">
                Password
              </label>
              <div className="relative">
                <input
                  {...register("password")}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  className={`block w-full h-11 px-4 rounded-md border ${
                    errors.password ? "border-red-500" : "border-gray-300"
                  } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-3 flex items-center text-sm text-neutral-500"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="w-5 h-5" />
                  ) : (
                    <EyeIcon className="w-5 h-5" />
                  )}
                </button>
              </div>
              <FormError message={errors.password?.message} />
            </div>
            <div className="text-left">
              <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1 font-medium">
                Confirm password
              </label>
              <div className="relative">
                <input
                  {...register("confirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm your password"
                  className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${
                    errors.password ? "border-red-500" : "border-gray-300"
                  } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                />

                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute inset-y-0 right-3 flex items-center text-sm text-neutral-500"
                >
                  {showConfirmPassword ? (
                    <EyeSlashIcon className="w-5 h-5" />
                  ) : (
                    <EyeIcon className="w-5 h-5" />
                  )}
                </button>
              </div>
              <FormError message={errors.confirmPassword?.message} />
            </div>
            <ButtonPrimary
              translate="rounded-md"
              loading={loading}
              className="w-full"
              type="submit"
            >
              Reset your password
            </ButtonPrimary>
          </form>
        </div>
      </div>
      <span className="absolute right-3 top-3">
        <ButtonClose
          sizes="w-6 h-6"
          className="cursor-pointer"
          onClick={() => {
            setIsTokenRecieved(false);
            setIsResetPassword(false);
          }}
        />
      </span>
    </div>
  );
};

export default PageResetPassword;
