import React from "react";
import uiUseStore from "store/UIStore";
import { Section } from "types/ProviderDetails";

interface QnaSectionContentProps {
  data: Section;
}

const QnaSectionContent: React.FC<QnaSectionContentProps> = ({ data }) => {
  const { activeSubsectionId, setActiveSubsectionId } = uiUseStore();

  console.log(data.subSections);

  const consolidatedSubSections = data.subSections
    .map((subSection) => {
      const filteredQuestions = subSection.questions.filter((question) =>
        question.responses.some(
          (response) =>
            response.responseText && response.responseText.trim() !== ""
        )
      );
      return { ...subSection, questions: filteredQuestions };
    })
    .filter((subSection) => subSection.questions.length > 0);

  if (!data.subSections) return null;

  return (
    <div className="flex xs:flex-col md:flex-row mt-6">
      {/* Vertical Tabs for Subsections */}
      <div className="xs:w-full md:w-1/4">
        <div
          className="flex xs:flex-row md:flex-col 
          rounded-lg xs:overflow-auto scrollbar-hide overflow-hidden
          border-gray-200 dark:border-none"
        >
          {consolidatedSubSections.map((subSection) => (
            <button
              key={subSection.id}
              onClick={() => setActiveSubsectionId(subSection.id)}
              className={`p-4 text-left text-[0.85rem] rounded-lg whitespace-nowrap ${
                activeSubsectionId === subSection.id
                  ? "text-purple-700 bg-gray-100 dark:text-white dark:bg-gray-800"
                  : "text-gray-600 hover:bg-gray-50 dark:text-gray-400"
              }`}
            >
              {subSection.subSectionName?.trim() === "" ||
              subSection.subSectionName == null
                ? "Overview"
                : subSection.subSectionName.match(
                    /(Features|Information|Overview|Services)/i
                  )
                ? subSection.subSectionName.split(" ")[0]
                : subSection.subSectionName}
            </button>
          ))}
        </div>
      </div>

      {/* Subsection Content */}
      <div className="xs:w-full md:w-3/4 xs:pl-0 xs:pt-3 md:pl-6 md:pt-0 overflow-auto h-[500px] scrollbar-hide">
        {consolidatedSubSections
          .filter((subSection) => subSection.id === activeSubsectionId)
          .map((subSection) => (
            <div key={subSection.id}>
              {subSection.questions.map((question) => (
                <div
                  key={question.id}
                  className="mb-4 bg-gray-100 p-4 rounded-lg dark:bg-gray-800"
                >
                  <p className="font-medium text-[0.85rem]">
                    {question.questionText.replace(":", "")}
                  </p>
                  <ul className="list-disc pl-5 mt-2">
                    {question.responses
                      .filter(
                        (response) =>
                          response.responseText &&
                          response.responseText.trim() !== ""
                      )
                      .map((response) => {
                        // Regular expression to detect URLs
                        const urlRegex = /(https?:\/\/[^\s]+)/g;

                        // Split the response text into parts based on URLs
                        const parts = response.responseText.split(urlRegex);
                        const formattedText = parts.map((part, index) => {
                          if (part.match(urlRegex)) {
                            return (
                              <a
                                key={index}
                                href={part}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:underline"
                              >
                                {part}
                              </a>
                            );
                          }
                          return part;
                        });

                        return (
                          <li
                            key={response.id}
                            className="text-gray-700 text-[0.85rem] dark:text-gray-400 break-all cursor-pointer"
                          >
                            {response.responseText === "True"
                              ? "Yes"
                              : response.responseText === "False"
                              ? "No"
                              : formattedText}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default QnaSectionContent;
