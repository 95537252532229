import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Label from "shared/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import { ContactTypes } from "types/ContactTypes";
import { Services } from "services/service";
import { StatusMessages } from "constants/StatusMessages";
import appStore from "store/AppStore";
import { Config } from "constants/config";
import Notifier from "components/Notifier/Notifier";
import { NotifierModel } from "types/NotifierModel";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "components/FormError/FormError";

interface ContactFormData {
  fullName: string;
  email: string;
  message: string;
}

const schema = yup.object().shape({
  fullName: yup
    .string()
    .required(StatusMessages.SchemaMessage.FullNameRequired),
  email: yup
    .string()
    .required(StatusMessages.SchemaMessage.EmailRequired)
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      StatusMessages.SchemaMessage.EmailAddressValidation
    ),
  message: yup.string().required(StatusMessages.SchemaMessage.MessageRequired)
});

const PageContactFields = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ContactFormData>({
    resolver: yupResolver(schema)
  });
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [NotifierDetails, setNotifierDetails] = useState<NotifierModel>({
    message: "",
    mode: "error"
  });

  const { loading, setLoader } = appStore();

  const handleSendMessage: SubmitHandler<ContactFormData> = async (data) => {
    //e.preventDefault();

    setNotifierDetails({
      message: "",
      mode: "error"
    });

    setLoader(true);

    const token = captchaRef.current?.getValue();
    if (!token) {
      // toast.error(StatusMessages.ErrorMessage.CaptchaVerification, { position: "top-right" });
      setNotifierDetails({
        message: StatusMessages.ErrorMessage.CaptchaVerification,
        mode: "error"
      });
      setLoader(false);
      return;
    }

    captchaRef.current?.reset();

    const ContactMessage: ContactTypes = {
      fullName: data.fullName ?? "",
      email: data.email ?? "",
      message: data.message ?? ""
    };

    try {
      const result = await Services.CreateContact(ContactMessage);

      if (result.status.toLowerCase() === "success" || result.data.id > 0) {
        setLoader(false);
        setNotifierDetails({
          message: StatusMessages.SuccessMessages.MessageSent,
          mode: "success"
        });
      } else {
        setNotifierDetails({
          message: StatusMessages.ErrorMessage.WentWrongError,
          mode: "error"
        });
      }
    } catch (ex) {
      setLoader(false);

      setNotifierDetails({
        message: StatusMessages.ErrorMessage.WentWrongError,
        mode: "error"
      });
    }
  };

  return (
    <form
      className="lg:w-1/2 py-4 xs:w-full flex flex-col justify-start items-start px-8  rounded-xl bg-[#fafafa] dark:bg-neutral-800"
      onSubmit={handleSubmit(handleSendMessage)}
    >
      <label className="block w-full mt-5 mb-3">
        <Label>Full name</Label>
        <Input
          {...register("fullName")}
          placeholder="Enter you name"
          type="text"
          className={`${
            errors.fullName ? "border-red-500" : "border-gray-300"
          }`}
          rounded="rounded-lg"
        />
        <FormError message={errors.fullName?.message} />
      </label>
      <label className="block w-full mb-3">
        <Label>Email address</Label>
        <Input
          {...register("email")}
          placeholder="Enter your email address"
          type="text"
          className={`${errors.email ? "border-red-500" : "border-gray-300"}`}
          rounded="rounded-lg"
          onInvalid={(e) => {
            const target = e.target as HTMLInputElement;
            if (!target.value) {
              target.setCustomValidity(
                StatusMessages.SchemaMessage.EmailRequired
              );
            } else {
              target.setCustomValidity(
                StatusMessages.SchemaMessage.EmailAddressValidation
              );
            }
          }}
          onInput={(e) => {
            (e.target as HTMLInputElement).setCustomValidity("");
          }}
        />
        <FormError message={errors.email?.message} />
      </label>
      <label className="block w-full mb-5">
        <Label>Leave us message</Label>
        <Textarea
          {...register("message")}
          className={`rounded-lg ${
            errors.message ? "border-red-500" : "border-gray-300"
          }`}
          rows={6}
        />
        <FormError message={errors.message?.message} />
      </label>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={Config.KEY.SITE_KEY} // Replace with your Site Key
        theme={"light"}
        className="w-full mb-1 2xs:ml-[-20px] sm:ml-0"
        onChange={() => {
          setNotifierDetails({
            message: "",
            mode: "error"
          });
        }}
      />

      <Notifier
        notifierState={NotifierDetails.message !== ""}
        message={NotifierDetails.message}
        mode={NotifierDetails.mode}
      />

      <ButtonPrimary
        translate="rounded-md"
        loading={loading}
        disabled={!captchaRef.current?.getValue()}
        className="w-full my-3"
        type="submit"
      >
        Send Message
      </ButtonPrimary>
    </form>
  );
};

export default PageContactFields;
