import React, { FC } from "react";
import uiUseStore from "store/UIStore";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import care from "images/banners/care4.png";
import ForgotPassword from "./ForgotPassword";
import LoginModule from "./LoginModule";
import PageSignup from "containers/PageSignUp/PageSignUp";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const {
    setShowLogin,
    isForgotPassword,
    setIsForgotPassword,
    showSignup,
    setShowSignup
  } = uiUseStore();

  const handleEvent = () => {
    setIsForgotPassword(false);
  };

  return (
    <div className={`nc-PageLogin rounded-xl flex ${className}`}>
      <div className="w-[70rem] h-[43rem] rounded-lg 2xs:p-2 lg:p-0  flex flex-col md:flex-row">
        {/* Left Side */}
        <div className="hidden md:flex md:w-1/2 bg-gray-100 rounded-l-lg flex-col justify-center overflow-hidden">
          <div className="relative w-full h-full rounded-lg overflow-hidden">
            <img
              src={care}
              className="w-full h-full object-cover"
              alt="Healthcare Image"
            />
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          </div>
        </div>
        {/* Right Side */}
        {isForgotPassword ? (
          <ForgotPassword callback={handleEvent} />
        ) : showSignup ? (
          <PageSignup />
        ) : (
          <LoginModule />
        )}
      </div>
      <span className="absolute right-3 top-3">
        <ButtonClose
          sizes="w-6 h-6"
          className="cursor-pointer"
          onClick={() => {
            setShowLogin(false);
            setIsForgotPassword(false);
            setShowSignup(false);
          }}
        />
      </span>
    </div>
  );
};

export default PageLogin;
