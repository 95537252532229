import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "components/FormError/FormError";
import Notifier from "components/Notifier/Notifier";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NotifierModel } from "types/NotifierModel";
import * as yup from "yup";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import uiUseStore from "store/UIStore";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import appStore from "store/AppStore";
import { Services } from "services/service";
import { KEYS } from "constants/KeyConstants";

interface FormData {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
});

const LoginModule = () => {
  const {
    setIsForgotPassword,
    setShowLogin,
    setShowSignup,
    setIsLoggedIn,
    setIsVerificationPending
  } = uiUseStore();
  const { loading, setLoader, setProfileImage, setUserDetail } = appStore();

  const [NotifierState, setNotifierState] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [NotifierDetails, setNotifierDetails] = useState<NotifierModel>({
    message: "",
    mode: "error"
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const forgotPasswordClick = () => {
    setIsForgotPassword(true);
  };

  const onCreateAccountClick = () => {
    setShowSignup(true);
  };

  const verifyNowClicked = () => {
    setShowLogin(false);
    setIsVerificationPending(true);
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setNotifierState(false);
      setLoader(true);
      let result = await Services.Login(data.email, data.password);
      if (result) {
        localStorage.setItem(KEYS.ISLOGGEDIN, "true");
        localStorage.setItem(KEYS.EMAIL, data.email);
        localStorage.setItem(KEYS.TOKEN, result?.token!.toString());
        localStorage.setItem(KEYS.PROFILEIMAGE, result?.profilePicture!);
        localStorage.setItem(KEYS.USERNAME, result?.firstName);
        localStorage.setItem(KEYS.USERID, result?.id.toString());

        setProfileImage(result?.profilePicture!);
        setShowLogin(false);
        setIsLoggedIn(true);
        setUserDetail({
          id: result.id,
          email: result.email,
          firstName: result.firstName,
          lastName: result.lastName,
          phone: result.phone,
          profilePicture: result.profilePicture ?? ""
        });
        setProfileImage(result.profilePicture ?? "");
      }
    } catch (error: any) {
      if (error.message.includes("402")) {
        localStorage.setItem(KEYS.EMAIL, data.email);
        setNotifierDetails({
          message: error.message.split("-")[0],
          mode: "error",
          link: "Verfiy now",
          onClick: verifyNowClicked
        });
      } else {
        setNotifierDetails({ message: error.message, mode: "error" });
      }
      setNotifierState(true);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="w-full my-20 2xs:my-5 md:w-1/2 2xs:-4 sm:p-8 text-center align-middle justify-center">
      <div className="justify-center mb-4">
        <h3 className="text-[1.75rem] font-medium text-left py-[11px]">
          Welcome back 👋
        </h3>
        <h4 className="font-normal text-left text-[0.9rem] text-gray-500">
          Please sign in to continue
        </h4>
      </div>
      <Notifier
        notifierState={NotifierState}
        message={NotifierDetails.message}
        mode={NotifierDetails.mode}
        link={NotifierDetails.link}
        onClick={NotifierDetails.onClick}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-1 mt-4">
        <div className="text-left">
          <label className="text-neutral-800 dark:text-neutral-200 text-[0.85rem] ml-1 font-medium">
            Email
          </label>
          <input
            {...register("email")}
            type="text"
            placeholder="Enter your email address"
            className={`mt-1 block w-full h-11 px-4 py-2 text-sm rounded-md border ${
              errors.email ? "border-red-500" : "border-gray-300"
            } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
          />
          <FormError message={errors.email?.message} />
        </div>
        <div className="text-left">
          <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem] font-medium">
            Password
          </label>
          <div className="relative">
            <input
              {...register("password")}
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              className={`block w-full h-11 px-4 rounded-md border ${
                errors.password ? "border-red-500" : "border-gray-300"
              } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-sm text-neutral-500"
            >
              {showPassword ? (
                <EyeSlashIcon className="w-5 h-5" />
              ) : (
                <EyeIcon className="w-5 h-5" />
              )}
            </button>
          </div>
          <FormError message={errors.password?.message} />
        </div>
        <div
          className="text-right text-[0.85rem] pb-4 text-primary-500 hover:underline cursor-pointer font-normal"
          onClick={forgotPasswordClick}
        >
          Forgot password?
        </div>
        <ButtonPrimary
          translate="rounded-md"
          loading={loading}
          className="w-full"
          type="submit"
        >
          Login
        </ButtonPrimary>
      </form>
      <div className="text-[0.85rem] text-gray-500 text-center mt-6">
        Don't have account?{" "}
        <span
          className="text-primary-500 hover:underline cursor-pointer font-normal"
          onClick={onCreateAccountClick}
        >
          Sign up
        </span>
      </div>
    </div>
  );
};

export default LoginModule;
