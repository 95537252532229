import { Config } from "constants/config";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import x from "images/xtwitter.svg";
import xdark from "images/twitter-dark.png";
import xlight from "images/twitter-light.png";
import uiUseStore from "store/UIStore";
const info = [
  {
    title: "Email",
    icon: EnvelopeIcon,
    content: Config.KEY.EMAIL
  }
];

const socialPlatforms = [
  {
    title: "Linkedin",
    icon: "lab la-linkedin-in"
  },
  {
    title: "facebook",
    icon: "lab la-facebook-f"
  },
  {
    title: "Instagram",
    icon: "lab la-instagram"
  },

  {
    title: "twitter",
    icon: "lab la-twitter"
  }
];

const PageContactInfo = () => {
  const { isDarkUi } = uiUseStore();
  return (
    <div className="lg:w-1/2 xs:w-full flex md:flex-col xs:flex-col justify-start items-start px-4 space-y-10 xs:pt-0 md:pt-10">
      <p className="text-[#6334e3] font-semibold tracking-[3px] text-[1.3rem]">
        CONTACT US
      </p>
      <p className="xs:text-4xl text-neutral-900 dark:text-neutral-200 lg:text-[3.5rem] font-extrabold leading-[4rem] -ml-[5px]">
        Get in touch
      </p>
      <p className="text-neutral-500 dark:text-neutral-300 text-sm mb-10">
        We love questions and feedback - and we're always happy to help!
      </p>
      {info.map((e) => (
        <div className=" flex items-center justify-start space-x-8  bg-[#fafafa] dark:bg-neutral-800 px-10 py-3 rounded-xl border-[#ebebeb] dark:border-neutral-700 border-[1px]">
          <div className="p-3 bg-[#ebe6f8] dark:bg-neutral-700 rounded-xl">
            <e.icon className="w-8 h-8 text-[#6334e3] font-bold" />
          </div>
          <div className="w-full flex flex-col items-start justify-start">
            <p className="text-neutral-500 dark:text-neutral-300 text-sm">
              {e.title}
            </p>
            <p className="xs:text-[1rem] lg:text-[1.3rem] font-extrabold tracking-[1px]">
              {e.content}
            </p>
          </div>
        </div>
      ))}

      <div className="w-full flex items-start justify-start space-x-4 2xl:space-x-16">
        {socialPlatforms.map((e) => (
          <div
            className={`p-3 bg-[#f7f6f6] dark:bg-neutral-800 rounded-xl cursor-pointer`}
            title={e.title}
          >
            {e.title === "twitter" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#a)">
                  <path
                    fill="currentColor"
                    d="M13.158 2.058h2.248l-4.913 5.435 5.78 7.395h-4.525l-3.545-4.485-4.056 4.485h-2.25l5.255-5.813-5.545-7.017h4.64l3.205 4.1 3.706-4.1Zm-.79 11.527h1.246L5.57 3.293H4.233l8.135 10.292Z"
                  ></path>
                </g>
              </svg>
            ) : (
              <i className={`${e.icon} text-[1.9rem]`} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageContactInfo;
