import { KEYS } from "constants/KeyConstants";
import { useState, useRef, FormEvent, KeyboardEvent, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Services } from "services/service";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import appStore from "store/AppStore";
import uiUseStore from "store/UIStore";
import useCookie from "utils/useCookies";
import { StatusMessages } from "constants/StatusMessages";
import Notifier from "components/Notifier/Notifier";
import care from "images/banners/care4.png";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { NotifierModel } from "types/NotifierModel";

const PageOTP: React.FC = () => {
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [timer, setTimer] = useState<number>(60); // Initial timer value (60 seconds)
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const navigate = useNavigate();

  const [otpNotifierDetails, setOtpNotifierDetails] = useState<NotifierModel>({
    message: "",
    mode: "error"
  });

  const { setIsVerificationPending } = uiUseStore();
  const { removeCookie } = useCookie();

  useEffect(() => {
    setEmail(localStorage.getItem(KEYS.EMAIL) || "");
    handleResend();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timer > 0) {
      setResendEnabled(false);
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  const handleChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input if there’s a value and it's not the last box
    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (otp.length !== 4 || otp.includes("")) {
        setOtpNotifierDetails({
          message: StatusMessages.ErrorMessage.VerificationCodeMissing,
          mode: "error"
        });
        return;
      }

      const otpValue = otp.join("");

      setOtpNotifierDetails({ message: "", mode: "error" });
      setLoader(true);
      await Services.Verify(otpValue, email);
      removeCookie(KEYS.EMAIL);
      removeCookie(KEYS.VERIFICATIONPENDING);
      toast.success("Verification successful, Please login to continue");
      setIsVerificationPending(false);
    } catch (error) {
      setOtpNotifierDetails({
        message: StatusMessages.ErrorMessage.VerificationCode,
        mode: "error"
      });
    } finally {
      setLoader(false);
    }
  };

  const handleResend = async () => {
    try {
      setLoader(true);
      let email = localStorage.getItem(KEYS.EMAIL);
      if (email) {
        await Services.RetryVerification(email);
        // toast.success("Verification code sent to your email");
        setOtpNotifierDetails({
          message: StatusMessages.SuccessMessages.OtpVerification,
          mode: "success"
        });
      }
    } catch (error) {
      // toast.error(StatusMessages.ErrorMessage.OTPError);
      setOtpNotifierDetails({
        message: StatusMessages.ErrorMessage.OTPError,
        mode: "error"
      });
    } finally {
      setLoader(false);
    }

    setTimer(60); // Reset the timer
  };

  return (
    <div className={`nc-PageLogin rounded-xl flex`}>
      <div className="2xs:w-screen xs:w-[20rem] sm:w-[30rem] md:w-[50rem] lg:w-[70rem] md:h-[43rem] rounded-lg 2xs:p-2 lg:p-0 flex flex-col md:flex-row">
        <div className="hidden md:flex md:w-1/2 bg-gray-100 rounded-l-lg flex-col justify-center">
          <div className="relative h-full w-full rounded-lg overflow-hidden">
            <img
              src={care}
              className="object-cover h-full w-full"
              alt="Healthcare Image"
            />
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
          </div>
        </div>
        <div
          className="w-full my-20 2xs:my-5 md:w-1/2 2xs:p-0 sm:p-8 text-center align-middle justify-center"
          data-nc-id="OTPInput"
        >
          <div className="justify-center mb-4">
            <h3 className="text-[1.75rem] font-medium text-left py-[11px]">
              Complete Verification
            </h3>
            <h4 className="font-normal text-left text-[0.9rem] text-gray-500">
              Enter the verification code sent to{" "}
              <span className="font-semibold">{email}</span>
            </h4>
          </div>
          <Notifier
            notifierState={otpNotifierDetails.message !== ""}
            message={otpNotifierDetails.message}
            mode={otpNotifierDetails.mode}
          />
          <form onSubmit={handleSubmit} className="space-y-6 mt-4">
            <div className="flex space-x-6">
              {otp.map((_, index) => (
                <input
                  key={index}
                  type="text"
                  value={otp[index]}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                  maxLength={1}
                  className={`w-12 h-12 text-center border border-gray-300 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:bg-neutral-900 text-[1.2rem] font-semibold text-neutral-900 dark:text-neutral-100 ${
                    otp[index] ? "border-primary-300" : "border-gray-300"
                  }`}
                />
              ))}
            </div>
            <div className="pt-3">
              <ButtonPrimary
                translate="rounded-md"
                loading={loader}
                className="w-full"
                type="submit"
              >
                Verify
              </ButtonPrimary>
              <div className="text-[0.85rem] text-gray-500 mt-6 text-center">
                Didn't receive the code?{" "}
                <span
                  className={`text-primary-500 hover:underline cursor-pointer ${
                    resendEnabled ? "" : "pointer-events-none opacity-50"
                  }`}
                  onClick={resendEnabled ? handleResend : undefined}
                >
                  Resend{" "}
                  <span className="inline-block w-[20px] text-center">
                    {timer > 0 && `(${timer})`}
                  </span>
                </span>
              </div>
            </div>
          </form>
        </div>
        <span className="absolute right-3 top-3">
          <ButtonClose
            sizes="w-6 h-6"
            className="cursor-pointer"
            onClick={() => {
              setIsVerificationPending(false);
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default PageOTP;
