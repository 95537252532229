import React, { FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import uiUseStore from "store/UIStore";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import appStore from "store/AppStore";
import { Services } from "services/service";
import OTPInput from "./OTPInput";
import toast from "react-hot-toast";
import useCookie from "utils/useCookies";
import { KEYS } from "constants/KeyConstants";
import lottie from "lottie-web";
import FormError from "components/FormError/FormError";
import Notifier from "components/Notifier/Notifier";
import { NotifierModel } from "types/NotifierModel";
import { formatPhoneNumber } from "utils/converter";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { StatusMessages } from "constants/StatusMessages";

export interface PageSignupProps {
  className?: string;
  callback?: () => void;
}

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(StatusMessages.SchemaMessage.EmailAddressValidation)
    .required(StatusMessages.SchemaMessage.EmailRequired),
  firstName: yup
    .string()
    .required(StatusMessages.SchemaMessage.FirstNameRequired)
    .min(3, StatusMessages.SchemaMessage.FirstNameCondition.minimumValidation)
    .max(25, StatusMessages.SchemaMessage.FirstNameCondition.maximumValidation),
  lastName: yup
    .string()
    .required(StatusMessages.SchemaMessage.LastNameRequired)
    .max(25, StatusMessages.SchemaMessage.LastNameCondition.maximumValidation),
  phone: yup
    .string()
    .required(StatusMessages.SchemaMessage.PhoneNumberRequired)
    .matches(
      /^\d{3}-?\d{3}-?\d{4}$/,
      StatusMessages.SchemaMessage.PhoneNumberValid
    )
    .required(StatusMessages.SchemaMessage.PhoneNumberRequired),
  password: yup
    .string()
    .min(8, StatusMessages.SchemaMessage.PasswordValidation)
    .required(StatusMessages.SchemaMessage.PasswordRequired)
});

const PageSignup: FC<PageSignupProps> = ({ className = "" }) => {
  const { setIsLoginPage, setShowLogin, setShowSignup } = uiUseStore();
  const { loading, setLoader } = appStore();
  const [isVerificationStarted, setisVerificationStarted] = useState(false);
  const [email, setEmail] = useState("");
  const { setCookie, removeCookie } = useCookie();
  const signUpRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const [NotifierState, setNotifierState] = useState(false);

  const [NotifierDetails, setNotifierDetails] = useState<NotifierModel>({
    message: "",
    mode: "error"
  });

  const [otpNotifierDetails, setOtpNotifierDetails] = useState<NotifierModel>({
    message: "",
    mode: "error"
  });

  useEffect(() => {
    setIsLoginPage(true);
    if (signUpRef.current) {
      const lottieInstance = lottie.loadAnimation({
        container: signUpRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: {}
      });

      return () => {
        lottieInstance.destroy();
      };
    }
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setNotifierState(false);
      setLoader(true);
      await Services.Register(
        data.firstName,
        data.lastName,
        data.phone.replaceAll(/-/g, ""),
        data.email,
        data.password
      );
      setEmail(data.email);
      setCookie(KEYS.EMAIL, data.email, 20);
      setCookie(KEYS.VERIFICATIONPENDING, "true", 20);
      // toast.success("Verification code sent to your email");
      setisVerificationStarted(true);
    } catch (error: any) {
      setNotifierDetails({ message: error.message, mode: "error" });
      setNotifierState(true);
    } finally {
      setLoader(false);
    }
  };

  const onVerificationSubmit = async (otp: string) => {
    try {
      setOtpNotifierDetails({ message: "", mode: "error" });
      setLoader(true);
      await Services.Verify(otp, email);
      setisVerificationStarted(false);
      removeCookie(KEYS.EMAIL);
      removeCookie(KEYS.VERIFICATIONPENDING);
      setShowSignup(false);
      setShowLogin(true);
      // toast.success("Verification successful, Please login to continue");
    } catch (error) {
      setOtpNotifierDetails({
        message: StatusMessages.ErrorMessage.VerificationCode,
        mode: "error"
      });
    } finally {
      setLoader(false);
    }
  };

  const onCreateAccountClick = () => {
    setShowLogin(true);
    setShowSignup(false);
  };

  const onResendClick = async () => {
    try {
      setLoader(true);
      await Services.RetryVerification(email);
      // toast.success("Verification code sent to your email");
      setOtpNotifierDetails({
        message: StatusMessages.SuccessMessages.OtpVerification,
        mode: "success"
      });
    } catch (error) {
      toast.error(StatusMessages.ErrorMessage.OTPError);
    } finally {
      setLoader(false);
    }
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(event.target.value);
    setValue("phone", formattedPhone, { shouldValidate: true });

    if (/^\d{3}-?\d{3}-?\d{4}$/.test(formattedPhone)) {
      clearErrors("phone"); // Remove the phone error if it matches the format
    }
  };

  return (
    <div
      className={`w-full md:w-1/2 2xs:p-0 sm:p-10 text-center align-middle justify-center`}
    >
      {!isVerificationStarted ? (
        <>
          <div className="justify-center mb-4">
            <h3 className="text-[1.75rem] font-medium text-left py-[11px]">
              Sign up
            </h3>
            <h4 className="font-normal text-left text-[0.9rem] text-gray-500">
              Please create your account
            </h4>
          </div>

          <Notifier
            notifierState={NotifierState}
            message={NotifierDetails.message}
            mode={NotifierDetails.mode}
          />

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full mt-4 text-left"
          >
            <div className="flex justify-between gap-3">
              <div className="flex-1">
                <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem] font-medium">
                  First name
                </label>
                <input
                  {...register("firstName")}
                  type="text"
                  placeholder=""
                  className={`block w-full h-11 px-4 rounded-md border ${
                    errors.firstName ? "border-red-500" : "border-gray-300"
                  } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                />
                <FormError message={errors.firstName?.message} />
              </div>

              <div className="flex-1">
                <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem] font-medium">
                  Last name
                </label>
                <input
                  {...register("lastName")}
                  type="text"
                  className={`block w-full h-11 px-4 rounded-md border ${
                    errors.lastName ? "border-red-500" : "border-gray-300"
                  } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                />
                <FormError message={errors.lastName?.message} />
              </div>
            </div>

            <div className="">
              <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem] font-medium">
                Email
              </label>
              <input
                {...register("email")}
                type="text"
                className={`block w-full h-11 px-4 rounded-md border ${
                  errors.email ? "border-red-500" : "border-gray-300"
                } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
              />
              <FormError message={errors.email?.message} />
            </div>
            <div className="">
              <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem] font-medium">
                Phone
              </label>
              <input
                {...register("phone")}
                onChange={handlePhoneChange}
                type="text"
                className={`block w-full h-11 px-4 rounded-md border ${
                  errors.phone ? "border-red-500" : "border-gray-300"
                } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
              />
              <FormError message={errors.phone?.message} />
            </div>

            <div className="space-y-1 pb-8 relative">
              <label className="block text-neutral-800 dark:text-neutral-200 text-[0.85rem] font-medium">
                Password
              </label>
              <div className="relative">
                <input
                  {...register("password")}
                  type={showPassword ? "text" : "password"}
                  className={`block w-full h-11 px-4 rounded-md border ${
                    errors.password ? "border-red-500" : "border-gray-300"
                  } focus:ring focus:ring-primary-200 dark:bg-neutral-900`}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-3 flex items-center text-sm text-neutral-500"
                >
                  {showPassword ? (
                    <EyeSlashIcon className="w-5 h-5" />
                  ) : (
                    <EyeIcon className="w-5 h-5" />
                  )}
                </button>
              </div>
              <FormError message={errors.password?.message} />
            </div>
            <ButtonPrimary
              translate="rounded-md"
              loading={loading}
              className="w-full"
              type="submit"
            >
              Sign up
            </ButtonPrimary>
          </form>
          <div className="text-[0.85rem] text-gray-500 mt-6 text-center">
            Already registered?{" "}
            <span
              className="text-primary-500 hover:underline cursor-pointer font-normal"
              onClick={onCreateAccountClick}
            >
              Login
            </span>
          </div>
        </>
      ) : (
        <OTPInput
          loading={loading}
          onSubmit={onVerificationSubmit}
          onResendClick={onResendClick}
          errorDetails={otpNotifierDetails.message}
          email={email}
          notifierMode={otpNotifierDetails.mode}
        />
      )}
    </div>
  );
};

export default PageSignup;
