import React, { useState, useEffect } from "react";
import { Section, TotalReview } from "types/ProviderDetails";
import ReviewSection from "./ReviewSection";
import QnASectionContent from "./QnASectionContent";
import uiUseStore from "store/UIStore";

const QnASections = ({
  data,
  totalReview
}: {
  data: Section[];
  totalReview: TotalReview;
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // Track the active care type. Default to the first section's careType.
  const [activeCareType, setActiveCareType] = useState<string>(
    data[0]?.careType || ""
  );

  const {
    setActiveSubsectionId,
    isReviewSelected,
    setIsReviewSelected,
    activeSection,
    setActiveSection
  } = uiUseStore();

  // Group sections by careType
  const groupedSections = data.reduce(
    (acc: { [key: string]: Section[] }, section) => {
      if (!acc[section.careType]) {
        acc[section.careType] = [];
      }
      acc[section.careType].push(section);
      return acc;
    },
    {}
  );

  // Sections for the current active care type
  const activeSections = groupedSections[activeCareType] || [];

  // On mount and when care type changes, update the default active section and subsection
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    const defaultSection = activeSections[0];
    if (defaultSection) {
      setActiveSection(defaultSection.id);
      setActiveSubsectionId(defaultSection.subSections[0]?.id || 0);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeCareType]); // re-run when the active care type changes

  const handleCareTypeClick = (careType: string) => {
    setIsReviewSelected(false);
    setActiveCareType(careType);
    // Set active section to the first section of the chosen care type
    const firstSection = groupedSections[careType][0];
    if (firstSection) {
      setActiveSection(firstSection.id);
      setActiveSubsectionId(firstSection.subSections[0]?.id || 0);
    }
  };

  const handleSectionClick = (id: number) => {
    setIsReviewSelected(false);
    setActiveSection(id);
    // Reset active subsection when section changes
    const section = data.find((sec) => sec.id === id);
    if (section?.subSections.length) {
      setActiveSubsectionId(section.subSections[0].id);
    }
  };

  const activeSectionData = data.find(
    (section) => section.id === activeSection
  );

  const handleReviewClick = () => {
    setActiveSection(0);
    setIsReviewSelected(true);
  };

  // Consolidate active section data:
  // 1. For each subSection, filter out questions with no valid (non-empty) responses.
  // 2. Remove subSections that have no questions after cleanup.
  const cleanedActiveSectionData = activeSectionData
    ? {
        ...activeSectionData,
        subSections: activeSectionData.subSections
          .map((subSection) => ({
            ...subSection,
            questions: subSection.questions.filter((question) =>
              question.responses.some(
                (response) =>
                  response.responseText && response.responseText.trim() !== ""
              )
            )
          }))
          .filter((subSection) => subSection.questions.length > 0)
      }
    : null;

  return (
    <div className="pt-6 w-full">
      {/* Top-level Care Type Tabs */}
      <div className="flex border-b border-gray-200 xs:overflow-auto scrollbar-hide">
        {Object.keys(groupedSections).map((careType) => (
          <button
            key={careType}
            onClick={() => handleCareTypeClick(careType)}
            className={`mr-6 pb-2 text-[0.85rem] ${
              activeCareType === careType
                ? "text-purple-600 border-b-4 border-purple-700 dark:text-white dark:border-white"
                : "text-gray-600 border-b-4 border-transparent hover:text-gray-800 dark:text-gray-400"
            }`}
          >
            {careType}
          </button>
        ))}
      </div>

      {/* Section Tabs for the Active Care Type */}
      <div className="flex border-b border-gray-200 xs:overflow-auto scrollbar-hide pt-4">
        {activeSections
          .filter((section) =>
            section.subSections.some((subSection) =>
              subSection.questions.some((question) =>
                question.responses.some((response) => response.responseText)
              )
            )
          )
          .map((section) => (
            <button
              key={section.id}
              onClick={() => handleSectionClick(section.id)}
              className={`mr-6 pb-2 text-[0.85rem] ${
                activeSection === section.id
                  ? "text-purple-600 border-b-4 border-purple-700 dark:text-white dark:border-white"
                  : "text-gray-600 border-b-4 border-transparent hover:text-gray-800 dark:text-gray-400"
              }`}
            >
              {section.sectionName !== ""
                ? section.sectionName.split(" ")[0]
                : "About"}
            </button>
          ))}
        {totalReview && (
          <button
            onClick={handleReviewClick}
            className={`mr-6 pb-2 text-[0.85rem] ${
              isReviewSelected
                ? "text-purple-600 border-b-4 border-purple-700 dark:text-white dark:border-white"
                : "text-gray-600 border-b-4 border-transparent hover:text-gray-800 dark:text-gray-400"
            }`}
          >
            Review
          </button>
        )}
      </div>

      {isReviewSelected ? (
        <ReviewSection data={totalReview} />
      ) : (
        // Pass the consolidated data to QnASectionContent.
        cleanedActiveSectionData && (
          <QnASectionContent data={cleanedActiveSectionData} />
        )
      )}
    </div>
  );
};

export default QnASections;
