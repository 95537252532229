import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import StartRating from "components/StartRating/StartRating";
import React, { useEffect, useState } from "react";
import { ProviderDialog } from "./ProviderDialog";
import { Providers, Section } from "types/ProviderDetails";
import { Services } from "services/service";
import uiUseStore from "store/UIStore";
import appStore from "store/AppStore";
import Badge from "shared/Badge/Badge";
import {
  BuildingOfficeIcon,
  MapPinIcon,
  PhoneIcon
} from "@heroicons/react/24/outline";
import { TitleCase, formatPhoneNumber } from "utils/converter";
import ImageSlider from "components/ImageSlider/ImageSlider";
import Tooltip from "shared/Tooltip/Tooltip";
import { APP_CONSTANTS } from "constants/AppConstants";
import { KEYS } from "constants/KeyConstants";
import TouchScreenIdentifier from "utils/touchScreenIdentifier";
import { AddWishlist } from "types/AddWishList";
import {
  extractCMSRatingsSubSection,
  filterSections,
  mapCMSRatingsToCMSRatings
} from "contains/makers";

interface ProviderCardProps {
  data: Providers;
  isLite?: boolean;
  fromDrawer?: boolean;
}

export const ProviderCard: React.FC<ProviderCardProps> = ({
  data,
  isLite = false,
  fromDrawer = false
}) => {
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const {
    isMoreInfoDialogOpen,
    setIsMoreInfoDialogOpen,
    servicesTag,
    setShowLogin
  } = uiUseStore();
  const { setSavedProviderList, savedProviderList, userDetail } = appStore();
  const [isSelected, setIsSelected] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const isProviderSelected = savedProviderList
      ? savedProviderList.filter((profile) => profile.name === data.name)
          .length > 0
      : false;

    setIsSelected(isProviderSelected);
  }, [savedProviderList, data, isSelected]);

  useEffect(() => {
    if (TouchScreenIdentifier()) {
      setIsTouchDevice(true);
    } else {
      setIsTouchDevice(false);
    }
  }, [isSelected]);

  useEffect(() => {
    if (!isMoreInfoDialogOpen) {
      setMoreInfoOpen(false);
    }
  }, [isMoreInfoDialogOpen]);

  const LoadProviderInfo = async (cardDetail: Providers) => {
    let result: Section[] = filterSections(
      await Services.LoadQnA(cardDetail.code)
    );

    let cmsSection = extractCMSRatingsSubSection(result);
    if (cmsSection) {
      cardDetail.isRatingsAviable = true;
      cardDetail.rating = mapCMSRatingsToCMSRatings(cmsSection);
    }

    let data = cardDetail;
    data.sections = result;

    //setIsHomePage(false);
    setIsMoreInfoDialogOpen(true);
    setMoreInfoOpen(true);
  };

  const savedProviders = async () => {
    const isLoggedIn = localStorage.getItem(KEYS.ISLOGGEDIN) === "true";
    if (isLoggedIn) {
      const customerId =
        userDetail.id && userDetail.id !== 0
          ? userDetail.id
          : parseInt(localStorage.getItem(KEYS.USERID) || "0", 10);
      if (!isSelected) {
        if (savedProviderList) {
          setSavedProviderList(savedProviderList.concat(data));
          let wishListBody: AddWishlist = {
            customerId: customerId,
            providerId: data.id,
            providercode: data.code,
            serviceTag: servicesTag ?? ""
          };
          await Services.AddWishlist(wishListBody);
        } else {
          setSavedProviderList([data]);
          let wishListBody: AddWishlist = {
            customerId: customerId,
            providerId: data.id,
            providercode: data.code,
            serviceTag: servicesTag ?? ""
          };
          await Services.AddWishlist(wishListBody);
        }
      } else {
        if (savedProviderList) {
          setSavedProviderList(
            savedProviderList?.filter((details) => data.name !== details.name)
          );
          const providerToDelete = savedProviderList.find(
            (details) => data.name === details.name
          );
          if (providerToDelete) {
            await Services.DeleteWishlist(providerToDelete.code, customerId);
          }
        }
      }
    } else {
      setShowLogin(true);
    }
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full xs:h-48 md:h-56 md:w-72  md:border-r dark:border-neutral-800 xs:rounded-tr-2xl xs:rounded-tl-2xl md:rounded-l-2xl overflow-hidden">
        <ImageSlider
          images={
            data.images && data.images.length > 0
              ? data.images.map((img) => img.imagePath)
              : [APP_CONSTANTS.DUMMY_IMAGE]
          }
        />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="xs:grid grid-cols-2 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <MapPinIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-400" />
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data.distanceInMiles} miles
            </span>
          </div>
        </div>

        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <PhoneIcon className="h-4 w-4 text-neutral-500 dark:text-neutral-400" />
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {data.phoneNumber && data.phoneNumber.length > 0
                ? formatPhoneNumber(data.phoneNumber[0].phoneNumber)
                : "Unavailable"}{" "}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow px-3 xs:py-4 md:py-3 sm:p-5 flex flex-col ">
        <div className="xs:space-y-4 md:space-y-3">
          <div className="flex items-center space-x-2 justify-between">
            <h2 className="text-lg font-medium capitalize inline-flex gap-x-0">
              <Tooltip
                text={TitleCase(data.name)}
                position={"left-[0px] top-[30px]"}
              >
                <span
                  className={`line-clamp-1 ${
                    !isLite && !fromDrawer
                      ? "hover:underline cursor-pointer"
                      : ""
                  } min-w-[100px] max-w-80 text-ellipsis pr-1`}
                  onClick={
                    isLite && fromDrawer
                      ? undefined
                      : () => LoadProviderInfo(data)
                  }
                >
                  {TitleCase(data.name)}
                </span>
              </Tooltip>
              {/* {data.rating?.overall! >= 5 && (
                <Tooltip text={"Verified"} position={"left-[0px] top-[30px]"}>
                  <CheckBadgeIcon className="h-7 w-7 mr-1 text-emerald-500 dark:text-emerald-100" />
                </Tooltip>
              )}
              {data.rating?.overall! <= 2 && (
                <Tooltip
                  text={"Poorly rated"}
                  position={"left-[0px] top-[30px]"}
                >
                  <ExclamationCircleIcon className="h-7 w-7 mr-1 text-red-500 dark:text-red-500" />
                </Tooltip>
              )} */}
            </h2>
            <BtnLikeIcon
              isLiked={isSelected}
              className="text-neutral-500 dark:text-neutral-100"
              onClick={savedProviders}
              colorClass={`${
                isSelected
                  ? !isTouchDevice
                    ? "text-red-500 bg-opacity-30 hover:bg-opacity-50 dark:text-red-500 dark:bg-opacity-30 dark:hover:bg-opacity-50"
                    : "text-red-500 bg-opacity-30 dark:text-red-500 dark:bg-opacity-30"
                  : !isTouchDevice
                  ? "text-gray bg-opacity-30 hover:text-red-500 dark:text-gray dark:bg-opacity-30 dark:hover:text-red-500"
                  : "text-gray bg-opacity-30 dark:text-gray dark:bg-opacity-30"
              }`}
            />
          </div>
          <div className="text-sm text-neutral-500 dark:text-neutral-100 w-full flex items-center justify-start md:space-x-3 xs:space-x-1 flex-wrap md:space-y-0">
            {/* {data.isSponsored && (
              <div className="flex items-center space-x-2">
                <Badge
                  name={
                    <div className="flex items-center">
                      <InformationCircleIcon className="h-4 w-4 mr-1" />
                      <span className="text-xs font-body">Sponsored</span>
                    </div>
                  }
                  color="yellow"
                />
              </div>
            )} */}
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-user-friends"></i>
                  <span className="ml-1">
                    {data.services.find((service) => service === servicesTag) ??
                      data.services[0]}
                  </span>
                </div>
              }
              color="green"
            />
            {/* {data.isPreffered && (
              <Badge
                name={
                  <div className="flex items-center">
                    <img
                      src={buildTenantDetails().logo.symbol}
                      className="h-2.5 w-2.5"
                      alt="load again"
                    />
                    <span className="ml-1">Recommended</span>
                  </div>
                }
                color="purple"
              />
            )} */}
          </div>

          <div className="text-sm text-neutral-500 dark:text-neutral-100">
            <div className="flex items-center space-x-2">
              <BuildingOfficeIcon className="h-5 w-5 text-neutral-500 dark:text-neutral-400" />
              <span>
                {data.locations[0].address}, {data.locations[0].city},{" "}
                {data.locations[0].state},{" "}
                {data.locations[0].postalCode.split("-")[0]}
              </span>
            </div>
          </div>
        </div>
        <div className="xs:block w-14 border-neutral-100 dark:border-neutral-800 my-2"></div>
        {renderTienIch()}
        <div className="w-14 border-neutral-100 dark:border-neutral-800 my-2"></div>
        <div className="flex justify-between items-center">
          <StartRating
            reviewCount={5}
            point={data.rating?.overall ?? 0}
            className="pr-7 sm:pr-0"
          />
          <span className="text-base font-semibold text-secondary-500">
            <div className="text-sm font-normal text-neutral-500 dark:text-neutral-100 space-y-4">
              <div className="flex items-center space-x-1 cursor-pointer">
                {!isLite &&
                  (fromDrawer ? (
                    <span
                      className="font-normal text-red-500 hover:text-red-900 dark:hover:text-red-700"
                      onClick={savedProviders}
                    >
                      Remove from wishlist
                    </span>
                  ) : (
                    <span
                      className="font-thin text-primary-500 hover:text-primary-900 dark:hover:text-primary-700"
                      onClick={() => LoadProviderInfo(data)}
                    >
                      More Info
                    </span>
                  ))}
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH  group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-visible hover:shadow-xl transition-shadow will-change-transform`}
      data-nc-id="StayCardH"
    >
      <ProviderDialog
        closeModal={setMoreInfoOpen}
        open={moreInfoOpen}
        details={data}
      />
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {!isLite && renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};
